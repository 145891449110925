export const EN_US = {
  小计: 'Total',
  总计: 'Total',
  总和: '(SUM)',
  项: 'items',
  已选择: 'selected',
  序号: 'Index',
  度量: 'Measure',
  数值: 'Measure',
  共计: 'Total',
  条: '',
  隐藏: 'Hide',
  趋势: 'Trend',
  组内升序: 'Group ASC',
  组内降序: 'Group DESC',
  升序: 'ASC',
  降序: 'DESC',
  不排序: 'No order',
  '，': ', ',
};
