export const ZH_CN = {
  小计: '小计',
  总计: '总计',
  总和: '（总和）',
  项: '项',
  已选择: '已选择',
  序号: '序号',
  度量: '度量',
  数值: '数值',
  共计: '共计',
  条: '条',
  趋势: '趋势',
  隐藏: '隐藏',
  组内升序: '组内升序',
  升序: '升序',
  降序: '降序',
  组内降序: '组内降序',
  不排序: '不排序',
  '，': '，',
};
